import React from 'react';
import { Control, useController } from 'react-hook-form';
import { CheckboxInput } from './Checkbox.styled';

interface CheckboxProps {
    className?: string;
    control: Control;
    isChecked?: boolean;
    isError?: boolean;
    name: string;
    select?: boolean;
}

export const Checkbox = ({
    className,
    control,
    isChecked,
    isError,
    name,
    select,
}: CheckboxProps) => {
    const {
        field: { onChange, value },
    } = useController({
        name,
        control,
    });
    return (
        <CheckboxInput
            checked={select ? isChecked : value}
            className={className}
            isError={isError}
            name={name}
            onChange={onChange}
            type="checkbox"
            id={name}
        />
    );
};
