import styled, { css } from 'styled-components';
import CheckboxIcon from 'images/checkbox-icon.svg';

export const CheckboxInput = styled.input<{ isError?: boolean }>(
    ({ theme: { colors }, isError }) => css`
        -webkit-appearance: none;
        -moz-appearance: none;
        -o-appearance: none;
        appearance: none;
        cursor: pointer;
        width: 24px;
        height: 24px;
        outline: none;
        border: 1px solid ${isError ? colors.error : colors.neutral200};
        background-color: ${colors.white};
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
            background-color: ${colors.primaryBlue60};
            border: 1px solid ${colors.primaryBlue500};
        }

        &:checked {
            border: 1px solid ${colors.primaryBlue500};
            background-color: ${colors.primaryBlue500};
            &::before {
                width: 14px;
                height: 10px;
                content: '';
                background: transparent url(${CheckboxIcon});
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
                display: block;
            }

            &:hover {
                border: 1px solid ${colors.primaryBlue700};
                background: ${colors.primaryBlue700};
            }
        }
    `,
);
